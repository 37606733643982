import {FormControlLabel, RadioGroup} from '@mui/material'
import {rootServiceAtom} from 'atoms/general'
import {partyAtom} from 'atoms/party'
import {greenCertificatesAtom, overviewDateFiltermAtom} from 'atoms/portfolioOverview'
import Box from 'components/Box'
import Button from 'components/Button'
import Filter, {IChoice} from 'components/Filter'
import FullscreenLoader from 'components/FullscreenLoader'
import InnerScroll from 'components/InnerScroll'
import Input from 'components/Input'
import MultiSelect from 'components/MultiSelect'
import Page from 'components/Page'
import CertificatesChart from 'components/Platform/Portfolio/GreenCertificate/CertificatesChart'
import Radio from 'components/Radio'
import Select from 'components/Select'
import Text from 'components/Text'
import {
  DeliveryPreferenceOptions,
  Escalation,
  ICreatePpaTenderReq,
  PpaKind,
  PpaTenderRequestState,
  PriceStructure,
  SubsidiesStatus,
} from 'domain/IPpaTenderRequest'
import {SiteProductionTechnology} from 'domain/ISite'
import {DateFormat, formatDate, getMonths, getPastYears} from 'helpers/date'
import {getHumanReadableCountry} from 'helpers/location'
import {getChangeEventValue} from 'helpers/misc'
import useAlerts from 'hooks/useAlerts'
import useLocalization from 'hooks/useLocalization'
import useTheme from 'hooks/useTheme'
import {useAtom, useAtomValue} from 'jotai'
import {capitalize} from 'lodash-es'
import React, {useState} from 'react'

const generateRange = (start, end) => Array.from({length: end - start}, (v, k) => k + start)
const generateEmptyOption = label => ({value: '', label, disabled: true})
const validateFields = fields => {
  let valid = true
  Object.keys(fields).forEach(key => {
    const value = fields[key]
    if (typeof value === 'boolean') return
    const isArray = Array.isArray(value)
    if ((isArray && value.length === 0) || (!isArray && !value)) {
      valid = false
    }
  })

  return valid
}

const ALL_TECHNOLOGIES_SLUG = 'ALL'

const PpaTenderRequest: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const {addError, addSuccess} = useAlerts()
  const rootService = useAtomValue(rootServiceAtom)
  const party = useAtomValue(partyAtom)
  const theme = useTheme()
  const months = getMonths()
  const years = getPastYears(4)
  const {data: certData, loading} = useAtomValue(greenCertificatesAtom)
  const [dateFilter, setDateFilter] = useAtom(overviewDateFiltermAtom)
  const technologyOptions = [
    SiteProductionTechnology.HYDRO,
    SiteProductionTechnology.WIND,
    SiteProductionTechnology.SOLAR,
  ]
  const allTechnologies: IChoice = {
    name: translate('All'),
    slug: ALL_TECHNOLOGIES_SLUG,
  }

  const defaultTenderData = {
    technologies: [allTechnologies],
    countries: [],
    state: '',
    maxAgeYears: undefined,
    deliveryStartsOn: '',
    deliveryEndsOn: '',
    annualVolumeMwh: '',
    subsidiesStatus: true,
    priceStructure: PriceStructure.FULLY_FIXED,
    escalation: Escalation.CPI,
    balancingPrefInPrice: false,
    certsInPrice: false,
    ppaKind: '',
    deliveryPreference: DeliveryPreferenceOptions.PAY_AS_PRODUCED,
    deliveryPeriodComment: '',
    deliveryPreferenceComment: '',
  }

  const [tenderData, setTenderData] = useState(defaultTenderData)

  const technologies: IChoice[] = [
    allTechnologies,
    ...technologyOptions.map(technology => ({
      name: translate(capitalize(SiteProductionTechnology[technology])).toUpperCase(),
      slug: technology,
    })),
  ]

  const handleDateChange = (event, key) => {
    const value = getChangeEventValue(event)

    setDateFilter({...dateFilter, [key]: value})
  }

  const resetForm = () => {
    setTenderData(defaultTenderData)
  }

  const handleTenderDataChange = (e, key, isNumber = true) => {
    const value = getChangeEventValue(e)

    setTenderData({...tenderData, [key]: isNumber ? +value : value})
  }

  const handleTenderBooleanChange = (e, key) => {
    const value = getChangeEventValue(e)

    setTenderData({...tenderData, [key]: value === 'true'})
  }

  const handleSend = async () => {
    const formattedTechnologies = tenderData.technologies.map(tech => tech.slug)

    const dataToValidate = {
      deliveryStartsOn: formatDate(tenderData.deliveryStartsOn, DateFormat.YEAR_MONTH_DAY),
      deliveryEndsOn: formatDate(tenderData.deliveryEndsOn, DateFormat.YEAR_MONTH_DAY),
      technologies: formattedTechnologies.includes(ALL_TECHNOLOGIES_SLUG)
        ? technologyOptions
        : (formattedTechnologies as SiteProductionTechnology[]),
      countries: tenderData.countries.map(country => country.value),
      state: PpaTenderRequestState.NEW_BUILD,
      annualVolumeMwh: +tenderData.annualVolumeMwh,
      subsidiesStatus: tenderData.subsidiesStatus ? SubsidiesStatus.SUBSIDIES : SubsidiesStatus.NOT_SUBSIDIES,
      ppaKind: tenderData.ppaKind as PpaKind,
      maxAgeYears: tenderData.maxAgeYears,
      priceStructure: tenderData.priceStructure,
      escalation: tenderData.escalation,
      balancingPrefInPrice: tenderData.balancingPrefInPrice,
      certsInPrice: tenderData.certsInPrice,
    }

    if (!validateFields(dataToValidate)) {
      addError(translate('All fields must be filled'))
      return
    }

    const requestBody: ICreatePpaTenderReq = {
      ...dataToValidate,
      deliveryPeriodComment: tenderData.deliveryPeriodComment,
      deliveryPreference:
        tenderData.deliveryPreference === DeliveryPreferenceOptions.PAY_AS_PRODUCED
          ? 'PAY_AS_PRODUCED'
          : {
              CUSTOM: {
                comment: tenderData.deliveryPreferenceComment,
              },
            },
    }

    try {
      await rootService.v2ContentService.sendPpaTenderRequest(party.id, requestBody)
      addSuccess(translate('Successfully sent tender request'))
      resetForm()
    } catch (e) {
      addError(translate('Failed to send tender request'), e?.correlationId, e?.message)
    }
  }

  if (loading) return <FullscreenLoader />

  return (
    <Page
      title={translate('Renewabl Tender')}
      description={translate('Send a tender request')}
      corner={
        <Box direction="row" align="center" gap={2}>
          <Box>
            <Button onClick={handleSend}>{translate('Send')}</Button>
          </Box>
        </Box>
      }
    >
      <InnerScroll noRightPad>
        <Box direction="row" gap={2} margin={{top: 0}}>
          <Select
            value={dateFilter.month}
            label={translate('Month')}
            options={months.map(month => ({value: month, label: month}))}
            onChange={e => handleDateChange(e, 'month')}
          />
          <Select
            value={dateFilter.year}
            label={translate('Year')}
            options={years.map(year => ({value: year, label: `${year}`}))}
            onChange={e => handleDateChange(e, 'year')}
          />
        </Box>
        <CertificatesChart certData={certData} />

        <Text size="xxlarge" pad={{vertical: 2}}>
          {translate('1. Generation preferences')}
        </Text>
        <Box margin={{top: 3}} direction="row" gap={5}>
          <Box>
            <Text size="xlarge" bold>
              {translate('Technology preferences')}
            </Text>
            <Filter
              choices={technologies}
              multiselect
              colored
              transparent
              skipDate
              technology
              selected={tenderData.technologies}
              name="technologies"
              setActiveChoice={choices => {
                if (Array.isArray(choices) && choices.length === 0) {
                  return
                }
                setTenderData({...tenderData, technologies: choices as IChoice[]})
              }}
            />
          </Box>

          <Box width="408px">
            <Text size="xlarge" bold margin={{bottom: 2}}>
              {translate('Location preferences')}
            </Text>
            <MultiSelect
              value={tenderData.countries as any}
              placeholder={translate('Any AIB country')}
              data={[
                ...theme.countries.map(country => ({
                  value: country,
                  label: getHumanReadableCountry(country, translate),
                })),
              ]}
              onChange={e => handleTenderDataChange(e, 'countries', false)}
              parse={value => value}
            />
          </Box>
        </Box>

        <Box margin={{top: 3}} direction="row" gap={5}>
          <Box width="400px">
            <Text size="xlarge" bold margin={{bottom: 2}}>
              {translate('Generation plant max age')}
            </Text>
            <Select
              value={tenderData.maxAgeYears}
              defaultValue={''}
              type="number"
              options={[
                generateEmptyOption(translate('Select max age')),
                ...generateRange(1, 16).map(year => ({
                  value: year,
                  label: `${year} ${translate(year === 1 ? 'year' : 'years')}`,
                })),
              ]}
              onChange={e => handleTenderDataChange(e, 'maxAgeYears')}
            />
          </Box>
          <Box width="400px">
            <Text size="xlarge" bold margin={{bottom: 2}}>
              {translate('Generation plant subsidies status')}
            </Text>
            <RadioGroup
              row
              value={tenderData.subsidiesStatus}
              onChange={e => handleTenderBooleanChange(e, 'subsidiesStatus')}
            >
              <FormControlLabel value={true} control={<Radio />} label={translate('With subsidies')} />
              <FormControlLabel value={false} control={<Radio />} label={translate('Without subsidies')} />
            </RadioGroup>
          </Box>
        </Box>
        <Box margin={{top: 3}} direction="row" gap={5}>
          <Box width="400px">
            <Text size="xlarge" bold margin={{bottom: 2}}>
              {translate('Generation plant type')}
            </Text>
            <Select
              value={tenderData.state}
              options={[
                generateEmptyOption(translate('Select plant type')),
                {value: PpaTenderRequestState.NEW_BUILD, label: translate('New build')},
                {value: PpaTenderRequestState.OPERATIONAL, label: translate('Operational')},
                {value: PpaTenderRequestState.ANY, label: translate('Any')},
              ]}
              onChange={e => handleTenderDataChange(e, 'state', false)}
            />
          </Box>
        </Box>

        <Text size="xxlarge" pad={{vertical: 2}}>
          {translate('2. Delivery preferences')}
        </Text>

        <Box margin={{top: 3}} direction="row" gap={5}>
          <Box width="400px">
            <Text size="xlarge" bold margin={{bottom: 2}}>
              {translate('Delivery period')}
            </Text>
            <Box direction="row" gap={2} margin={{top: -2}} width="100%">
              <Box width="50%">
                <Input
                  type="date"
                  label={translate('start')}
                  margin={0}
                  value={tenderData.deliveryStartsOn}
                  onChange={e => handleTenderDataChange(e, 'deliveryStartsOn', false)}
                />
              </Box>
              <Box width="50%">
                <Input
                  type="date"
                  label={translate('end')}
                  margin={0}
                  value={tenderData.deliveryEndsOn}
                  onChange={e => handleTenderDataChange(e, 'deliveryEndsOn', false)}
                />
              </Box>
            </Box>
          </Box>
          <Box width="400px">
            <Text size="xlarge" bold margin={{bottom: 2.5}}>
              {translate('Delivery period comments')}
            </Text>
            <Box>
              <Input
                value={tenderData.deliveryPeriodComment}
                onChange={e => handleTenderDataChange(e, 'deliveryPeriodComment', false)}
              />
            </Box>
          </Box>
        </Box>

        <Box margin={{top: 3}} direction="row" gap={5}>
          <Box width="400px">
            <Text size="xlarge" bold margin={{bottom: 2}}>
              {translate('Volume preference')}
            </Text>
            <RadioGroup
              row
              value={tenderData.deliveryPreference}
              onChange={e => handleTenderDataChange(e, 'deliveryPreference', false)}
            >
              <FormControlLabel
                value={DeliveryPreferenceOptions.PAY_AS_PRODUCED}
                control={<Radio />}
                label={translate('Pay as produced')}
              />
              <FormControlLabel
                value={DeliveryPreferenceOptions.CUSTOM}
                control={<Radio />}
                label={translate('Custom')}
              />
            </RadioGroup>
          </Box>
          <Box width="400px">
            <Text size="xlarge" bold margin={{bottom: 2}}>
              {translate('Annual volume estimate')}
            </Text>
            <Box>
              <Input
                value={tenderData.annualVolumeMwh}
                suffix={translate('MWh ')}
                type="number"
                placeholder="10000"
                onChange={e => handleTenderDataChange(e, 'annualVolumeMwh', false)}
              />
            </Box>
          </Box>
        </Box>

        <Box margin={{top: 3}} direction="row" gap={5}>
          {tenderData.deliveryPreference === DeliveryPreferenceOptions.CUSTOM && (
            <Box width="400px">
              <Text size="xlarge" bold margin={{bottom: 2}}>
                {translate('Custom volume preference description')}
              </Text>
              <Box>
                <Input
                  value={tenderData.deliveryPreferenceComment}
                  onChange={e => handleTenderDataChange(e, 'deliveryPreferenceComment', false)}
                />
              </Box>
            </Box>
          )}
        </Box>

        <Text size="xxlarge" pad={{vertical: 2}}>
          {translate('3. Contract preferences')}
        </Text>

        <Box margin={{top: 3}} direction="row" gap={5}>
          <Box width="400px" margin={{top: -2}}>
            <Text size="xlarge" bold margin={{bottom: 2}}>
              {translate('PPA type')}
            </Text>
            <Select
              value={tenderData.ppaKind}
              options={[
                generateEmptyOption(translate('Select PPA type')),
                {value: PpaKind.VIRTUAL, label: translate('Virtual')},
                {value: PpaKind.PHYSICAL, label: translate('Physical')},
                {value: PpaKind.ANY, label: translate('Any')},
              ]}
              onChange={e => handleTenderDataChange(e, 'ppaKind', false)}
            />
          </Box>
          <Box width="400px" margin={{top: -2}}>
            <Text size="xlarge" bold margin={{bottom: 2}}>
              {translate('Price structure')}
            </Text>
            <Select
              value={tenderData.priceStructure}
              options={[
                generateEmptyOption(translate('Select price structure')),
                {value: PriceStructure.FULLY_FIXED, label: translate('Fully fixed')},
                {value: PriceStructure.FIXED_WITH_ANNUAL_ESCALATION, label: translate('Fixed with annual escalation')},
                {value: PriceStructure.BALANCING_PREFERENCE, label: translate('Balancing preference')},
              ]}
              onChange={e => handleTenderDataChange(e, 'priceStructure', false)}
            />
          </Box>
        </Box>

        <Box margin={{top: 3}} direction="row" gap={5}>
          <Box width="400px">
            <Text size="xlarge" bold margin={{bottom: 2}}>
              {translate('Balancing preference in price')}
            </Text>
            <RadioGroup
              row
              style={{paddingLeft: '10px'}}
              value={tenderData.balancingPrefInPrice ? 'true' : 'false'}
              onChange={e => handleTenderBooleanChange(e, 'balancingPrefInPrice')}
            >
              <FormControlLabel value="true" control={<Radio />} label={translate('Include in price')} />
              <FormControlLabel value="false" control={<Radio />} label={translate('Exclude from price')} />
            </RadioGroup>
          </Box>
          <Box width="400px">
            <Text size="xlarge" bold margin={{bottom: 2}}>
              {translate('Certs in price')}
            </Text>
            <RadioGroup
              row
              style={{paddingLeft: '10px'}}
              value={tenderData.certsInPrice ? 'true' : 'false'}
              onChange={e => handleTenderBooleanChange(e, 'certsInPrice')}
            >
              <FormControlLabel value="true" control={<Radio />} label={translate('Include in price')} />
              <FormControlLabel value="false" control={<Radio />} label={translate('Exclude from price')} />
            </RadioGroup>
          </Box>
        </Box>
        <Box margin={{top: 3}} direction="row" gap={5}>
          <Box width="200px">
            <Text size="xlarge" bold margin={{bottom: 2}}>
              {translate('Escalation')}
            </Text>
            <RadioGroup
              row
              style={{paddingLeft: '10px'}}
              value={tenderData.escalation}
              onChange={e => handleTenderDataChange(e, 'escalation', false)}
            >
              <FormControlLabel value={Escalation.CPI} control={<Radio />} label={translate('CPI')} />
              <FormControlLabel value={Escalation.FIXED} control={<Radio />} label={translate('Fixed')} />
            </RadioGroup>
          </Box>
        </Box>
        <Box pad={{vertical: 3}} />
      </InnerScroll>
    </Page>
  )
}

export default PpaTenderRequest
